import React, { useState, useEffect } from 'react';
import Logo from '../../assets/logo_new_2.svg';
import TextLogo from '../../assets/text_logo_new.svg';
import home from '../../assets/main.jpg';
import product1 from '../../assets/product1.jpg';
import './style.css';
import { DarkMode, Facebook, Instagram, Mail, PhoneAndroid, Twitter, WbSunny } from '@mui/icons-material';
// import { IconButton } from '@mui/material';
import Carousel from '../../components/molecules/Carousel';
import Navbar from '../../components/organisms/NavigasiBar';
import TimelineComponenet from '../../components/molecules/Timeline';
import WaveTop from '../../components/molecules/WaveTop';
import WaveBottom from '../../components/molecules/WaveBottom';
import InfoPanelLeft from '../../components/organisms/InfoPanelLeft';
import InfoPanel from '../../components/organisms/InfoPanel';


const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const [isMessageVisible, setIsMessageVisible] = useState(true);

  const closeMessage = () => {
    setIsMessageVisible(false);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])


  useEffect(() => {
    const handleScroll = () => {
      const sections = ['beranda', 'tentang kami', 'visi misi', 'layanan', 'program', 'kontak', 'sponsored', 'our'];
      sections.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          element.classList.toggle('visible', rect.top < window.innerHeight && rect.bottom > 0);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementTop - 275, behavior: 'smooth' });
    }
  };

  return (
    <div className={`Header w-full h-full overflow-x-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="fixed flex gap-4 w-auto px-6 items-center justify-end z-[100] right-0 bottom-6">
        {isMessageVisible && (
          <div className="w-[250px] text-xs bg-gray-700 text-white py-2 px-4 rounded-lg flex flex-col items-end justify-center space-x-2">
            <span className='w-fit text-wrap'>Ini adalah tombol untuk mengganti mode gelap/terang</span>
            <button
              onClick={closeMessage}
              className="bg-transparent text-white font-semibold underline"
            >
              <p>tutup</p>
            </button>
          </div>
        )}
        <button onClick={toggleDarkMode} className="toggle-button flex items-center justify-center w-[50px] h-[50px] border-2 ring-2 ring-blue shadow-md">
          {isDarkMode ? <WbSunny /> : <DarkMode />}
        </button>
      </div>
      {/* HEADER + NAVIGATION */}
      <div className="fixed top-0 left-0 w-full z-[100]">
        <Navbar
          isDarkMode={isDarkMode}
          scrollToSection={scrollToSection}
        />
      </div>

      {/* HEAD CONTENT */}
      <div className="flex flex-col text-left pt-24">
        <div
          id="beranda"
          className={`grid gap-8 px-4 pt-8 py-12 text-lg items-start justify-center w-full ${isMobile ? 'grid-cols-1' : 'grid-cols-5'}`}
        >
          <div className={`content w-full flex flex-col ${isMobile ? 'col-span-1' : 'col-span-2'} gap-6 place-content-start`}>
            <div className={`font-bold mb-4 pt-4 text-left text-nowrap ${isMobile ? 'text-6xl' : 'text-8xl'} ${isDarkMode ? '' : 'opacity-75'}`}>
              <p>Apa itu </p>
              <p><u>Edunex</u> ?</p>
            </div>
            <h1 className="text-left font-bold text-2xl">
              Transformasi digital di pendidikan kini lebih mudah dari sebelumnya!
            </h1>
            <p className="text-left">
            EduNEX merupakan platform penyedia solusi teknologi pendidikan yang terintegrasi dan dirancang untuk memudahkan dan mendigitalisasi seluruh sistem proses belajar-mengajar, mulai dari sistem administrasi siswa hingga tenaga pengajar.
            </p>
          </div>

          {!isMobile && (
            <div className="w-full h-full col-span-3 flex items-center justify-center">
              <img id="image" src={home} alt="homepage" className="w-1/2" />
            </div>
          )}

          {isMobile && (
            <div className="w-full h-full flex items-center justify-center col-span-1">
              <img id="image" src={home} alt="homepage" className="w-1/2" />
            </div>
          )}
        </div>

        {/* BODY CONTENT */}
        {/* <div className={`w-full px-4 pt-8 py-12 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`} id='tentang kami'>
          <div className={`content w-full flex flex-col gap-6 place-content-start ${isMobile ? 'col-span-1' : 'col-span-2'}`}>
            <div className={`font-bold mb-4 pt-4 text-center text-nowrap ${isMobile ? 'text-6xl' : 'text-8xl'} ${isDarkMode ? '' : 'opacity-15'}`}>
              <h1>Tentang Kami</h1>
            </div>
            <p>Education N Experience ( EDUNEX ) adalah perusahaan inovasi pendidikan yang berkomitmen untuk membawa transformasi di dunia pendidikan melalui teknologi mutakhir.
              Kami menawarkan layanan digitalisasi dunia Pendidikan untuk memudahkan lembaga pendidikan, bersama guru, siswa, dan orang tua dalam menghadapi tantangan pendidikan di masa depan.
              Produk unggulan kami meliputi sistem absensi digital, platform manajemen pembelajaran (Learning Management System), serta perangkat lunak untuk analisis data pendidikan. Solusi kami bertujuan untuk menciptakan ekosistem pendidikan yang terintegrasi, efisien, dan mudah diakses oleh semua kalangan.
              Sebagai pelopor teknologi pendidikan, EDUNEX bercita-cita untuk terus berinovasi memberikan layanan yang relevan dan berdampak nyata pada dunia pendidikan, baik di tingkat lokal maupun global.
            </p>
          </div>
          {!isMobile && (
            <div className="">
              <img src="" alt="" />
            </div>
          )}

          {isMobile && (
            <div className=""></div>
          )}
        </div> */}

        {/* <div className="flex flex-col px-4 pt-8 py-12" id='visi misi'>
          <div className={`content w-full flex flex-col gap-6 place-content-start ${isMobile ? 'col-span-1' : 'col-span-2'}`}>
            <div className={`font-bold mb-4 pt-4 text-center text-nowrap ${isMobile ? 'text-6xl' : 'text-8xl'} ${isDarkMode ? '' : 'opacity-15'}`}>
              <h1>Visi Misi</h1>
            </div>
            <div className="">
              <h1>Visi</h1>
              <p>Menjadi pemimpin global dalam transformasi pendidikan berbasis teknologi, dengan menciptakan solusi yang mendorong inovasi, inklusivitas, dan efektivitas dalam proses belajar-mengajar.</p>
            </div>
            <div className="">
              <h1>Misi</h1>
              <ul>
                <li>
                  Mengembangkan teknologi pendidikan yang inovatif dan mudah digunakan untuk mendukung ekosistem pendidikan modern.
                </li>
                <li>
                  Memberikan solusi terintegrasi yang meningkatkan efisiensi administrasi lembaga pendidikan dan proses pembelajaran.
                </li>
                <li>
                  akses pendidikan berkualitas bagi semua lapisan masyarakat melalui teknologi.
                </li>
                <li>
                  Berkontribusi pada pembangunan sumber daya manusia yang unggul dan berdaya saing di era digital.
                </li>
                <li>
                  Terus berinovasi sesuai dengan kebutuhan dunia pendidikan yang dinamis dan terus berkembang.
                </li>
              </ul>
            </div>
          </div>
          {!isMobile && (
            <div className="">
              <img src="" alt="" />
            </div>
          )}

          {isMobile && (
            <div className=""></div>
          )}
        </div> */}

        <InfoPanel
          isDarkMode={isDarkMode}
          darkBg={'bg-slate-800'}
          lightBg={'bg-slate-200'}
          title={'Tentang Kami'}
          id={'tentang kami'}
          context={`Education N Experience ( EDUNEX ) adalah perusahaan inovasi pendidikan yang berkomitmen untuk membawa transformasi di dunia pendidikan melalui teknologi mutakhir.`}
            context0={'            Produk unggulan kami meliputi sistem absensi digital, platform manajemen pembelajaran (Learning Management System), serta perangkat lunak untuk analisis data pendidikan. Solusi kami bertujuan untuk menciptakan ekosistem pendidikan yang terintegrasi, efisien, dan mudah diakses oleh semua kalangan.'}
            context1={'Kami menawarkan layanan digitalisasi dunia Pendidikan untuk memudahkan lembaga pendidikan, bersama guru, siswa, dan orang tua dalam menghadapi tantangan pendidikan di masa depan.'}
            context2={'Sebagai pelopor teknologi pendidikan, EDUNEX bercita-cita untuk terus berinovasi memberikan layanan yang relevan dan berdampak nyata pada dunia pendidikan, baik di tingkat lokal maupun global.'}
        />
        <div className={`w-full h-fit pt-12 py-12 flex flex-col justify-center`}>
          <div className={`flex justify-center pt-12 ${isDarkMode ? `` : `opacity-75`}`}>
            <h1 className={`font-bold mb-4 pt-4 ${isMobile ? 'text-6xl' : 'text-8xl'}`}>
              Visi Misi
            </h1>
          </div>
          {/* grid-cols-1 md:grid-cols-2 */}
          <div id='visi misi' className="section grid gap-8 px-4 py-12 text-lg items-start justify-center">
            <div className={`content flex flex-col gap-6 ${isMobile ? '' : 'mx-44'}`}>
              <div className="flex flex-col gap-6">
                <h1 className={`text-lg ${isMobile ? 'text-sm' : ''}`}>
                  Visi
                </h1>
                <p>
                  Menjadi pemimpin global dalam transformasi pendidikan berbasis teknologi, dengan menciptakan solusi yang mendorong inovasi, inklusivitas, dan efektivitas dalam proses belajar-mengajar.
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className={`text-lg ${isMobile ? 'text-sm' : ''}`}>Misi</h1>
                <ul className='list-disc'>
                  <li>
                    Mengembangkan teknologi pendidikan yang inovatif dan mudah digunakan untuk mendukung ekosistem pendidikan modern.
                  </li>
                  <li>
                    Memberikan solusi terintegrasi yang meningkatkan efisiensi administrasi lembaga pendidikan dan proses pembelajaran.
                  </li>
                  <li>
                    Menciptakan akses pendidikan berkualitas bagi semua lapisan masyarakat melalui teknologi.
                  </li>
                  <li>
                    Berkontribusi pada pembangunan sumber daya manusia yang unggul dan berdaya saing di era digital.
                  </li>
                  <li>
                    Terus berinovasi sesuai dengan kebutuhan dunia pendidikan yang dinamis dan terus berkembang.
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <InfoPanelLeft
          isDarkMode={isDarkMode}
          srcImg={product1}
          title={'Layanan'}
          id={"layanan"}
          heading={"Revolusi Administrasi Sekolah: Solusi Kehadiran Online & Pembelajaran Interaktif Terbaik"}
          context={"Revolusikan administrasi sekolah Anda dengan platform kehadiran online dan pembelajaran interaktif kami yang canggih. Lacak kehadiran dengan gampang dan buat siswa lebih terlibat seperti belum pernah sebelumnya. Transformasikan pendidikan secara efisien dengan solusi inovatif kami!"}
        />
        <div className={`flex justify-center pt-12 ${isDarkMode ? '' : 'opacity-75'}`}>
          <h1 className={`font-bold mb-4 pt-4 ${isMobile ? 'text-6xl' : 'text-8xl'}`}>Program</h1>
        </div>
        <div
          id="program"
          className="section content flex gap-8 px-4 py-12 text-lg items-start justify-center"
        >
          <Carousel isDarkMode={isDarkMode} isMobile={isMobile} />
        </div>

        <WaveTop
          classNamePath={`fill-current ${isDarkMode ? "text-slate-800" : "text-slate-200"
            }`}
        />
        <div
          className={`w-full h-fit pb-24 flex flex-col ${isDarkMode ? "bg-slate-800" : "bg-slate-200"
            } justify-center`}
        >
          <div id="sponsored" className="section">
            <div className="flex justify-center py-6 pb-16">
              <h1 className="text-3xl">Kami Dipercaya Oleh</h1>
            </div>
            <div className="grid grid-cols-5 px-6 py-2 gap-12">
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 1
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 2
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 3
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 4
              </div>
              <div className="w-full h-[100px] bg-blue-950 rounded-md">
                Example 5
              </div>
            </div>
          </div>
        </div>
        <WaveBottom
          classNamePath={`fill-current ${isDarkMode ? "text-slate-800" : "text-slate-200"
            }`}
        />

        <div
          id="our"
          className={`section grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-8 px-4 pt-12 py-12 text-lg items-start justify-center`}
        >
          <div className="content flex flex-col">
            <h1 className={`font-bold mb-4 ${isMobile ? 'text-5xl' : 'text-7xl'} pt-4 text-left ${isDarkMode ? '' : 'opacity-75'}`}>
              Kenapa Memilih Kami
            </h1>
            
            <p>
              Misi kami adalah mempermudah pengelolaan sekolah dan meningkatkan
              pengalaman belajar melalui kemajuan digital. Temukan cerita kami
              dan semangat yang mendorong kami untuk mengubah dunia pendidikan.
            </p>
          </div>

          <div className={`w-full flex ${isMobile ? ' mt-6' : ''}`}>
            <TimelineComponenet isDarkMode={isDarkMode} />
          </div>
        </div>

      </div>

      {/* <CarouselSlide /> */}
      {/* FOOTER */}
      <div
        className={`bg-slate-200 w-full h-fit px-16 py-6 ${isDarkMode ? "bg-slate-800 text-slate-100" : ""
          }`}
      >
        <div className="grid grid-cols-5 gap-12 text-lg font-medium">
          <div id="kontak" className="w-full h-auto py-6">
            <h1>Edunex Space</h1>
            <p>Jl. Unknown No. xx Banjarmasin, Kalimantan Selatan, 747521</p>
            <br />
            <ul>
              <li>
                <PhoneAndroid /> 082267678989
              </li>
              <br />
              <li>
                <Mail /> misterx@edunex.com
              </li>
              <br />
              <li>
                <div className="flex gap-6 w-full">
                  <Facebook />
                  <Instagram />
                  <Twitter />
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full h-auto flex flex-col gap-6 col-span-2 col-start-3 pt-[144px]">
            <p>Blog</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms</p>
          </div>
          <div className="relative flex flex-col items-center gap-[-10px] pt-[100px]">
            {/* Logo on top */}
            <img src={Logo} alt="black logo" className="size-[150px] z-10" />
            {/* Text logo below with a negative margin to overlap */}
            <img
              src={TextLogo}
              alt="black logo"
              className="size-[130px] z-0"
              style={{ marginTop: "-20px" }}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex h-auto px-12 py-4 items-center gap-2">
        &copy; <p>2024 Edunex | sebagian teks hilang ....</p>
      </div>
    </div>
  );
};

export default LandingPage;
